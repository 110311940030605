<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <div v-if="isLoaded">
      <sgv-form
        :method.sync="method"
        :options="options">

        <div class="form-row">
          <sgv-input-text
            label="ชื่อ"
            :disabled="$auth.disabled(method)"
            class="col-12"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-text>
        </div>

        <div class="form-row">
          <DocConfigFormAccount
            label="บัญชีขาย"
            :disabled="$auth.disabled(method)"
            :docType="docType"
            :templateType="templateType"
            class="col-12 col-sm-6"
            select="id"
            v-model="formData.sellAccountReceivableId"
            :validations="[
              {text: 'required!', value: $v.formData.sellAccountReceivableId.$dirty && !$v.formData.sellAccountReceivableId.required}
            ]">
          </DocConfigFormAccount>

          <DocConfigFormAccount
            v-if="type === 'accountItem'"
            label="บัญชีต้นทุนขาย"
            :disabled="$auth.disabled(method)"
            :docType="docType"
            :templateType="templateType"
            class="col-12 col-sm-6"
            select="id"
            v-model="formData.costOfSalesId"
            :validations="[
              {text: 'required!', value: $v.formData.costOfSalesId.$dirty && !$v.formData.costOfSalesId.required}
            ]">
          </DocConfigFormAccount>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <sgv-input-check
              label="ค่าเริ่มต้น"
              inline
              v-model="formData.isDefault">
            </sgv-input-check>
          </div>
        </div>
      </sgv-form>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DocConfigFormAccount from '@/views/doc_core/components/DocConfigFormAccount'
import {
  DETAIL_ACCOUNT,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  DESTROY_ACCOUNT,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}List`,
      formData: {
        name: null,
        costOfSalesId: null,
        sellAccountReceivableId: null,
        isDefault: false
      },
      isLoaded: false,
    }
  },
  validations () {
    if (this.type === 'accountItem') {
      return {
        formData: {
          name: { required },
          costOfSalesId: { required },
          sellAccountReceivableId: { required },
        }
      }
    } else if (this.type === 'accountService') {
      return {
        formData: {
          name: { required },
          sellAccountReceivableId: { required },
        }
      }
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_ACCOUNT(this.templateType),
        variables: {
          docType: this.docType,
          type: this.type,
          docConfigId: this.docConfigId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.account)})
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const input = {...v}
      const removeKeys = []

      removeKeys.forEach(x => delete input[x])

      return input
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_ACCOUNT(this.templateType),
        variables: {
          docType: this.docType,
          type: this.type,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, docConfigId: res.data.account.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_ACCOUNT(this.templateType),
        variables: {
          docType: this.docType,
          type: this.type,
          docConfigId: this.docConfigId,
          input
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_ACCOUNT(this.templateType),
        variables: {
          docType: this.docType,
          type: this.type,
          docConfigId: this.docConfigId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.docConfigId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DocConfigFormAccount
  }
}
</script>

<style lang="css">
</style>
